<template>
  <v-container id="dashboard-view" fluid tag="section">
    <default-loader-page v-if="loading" />
    
    <v-row>
      <v-col cols="12">
        <default-stats-top-card :stats="currentYearStats" :title="statTitle" />
      </v-col>
      <v-col cols="12">
        <default-stats-top-card
          :stats="stats[2]"
          title="КОЛИЧЕСТВО НЕФТЯНЫХ ОРГАНИЗАЦИЙ"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> <default-map-container /></v-col>
      <v-col cols="12"> <default-chart-container /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "DashboardView",
  components: {
    DefaultMapContainer: () => import("@/components/map/MapContainer.vue"),
    DefaultChartContainer: () =>
      import("@/components/chart/ChartContainer.vue"),
    DefaultStatsTopCard: () => import("@/views/Dashboard/StatsTopCard.vue"),
    DefaultLoaderPage: () => import("@/components/LoaderPage.vue"),
    
  },

  computed: {
    stats: get("stats/stats")
  },
  data() {
    return {
      loading: true,
      currentYearStats: [],
      unit: " т.",
      statTitle: `ОБЩИЙ ОБОРОТ НЕФТИ ЗА ${new Date().getFullYear()} ГОД`
    };
  },
  methods: {
    async initialize() {
      const url = "api/stat";
      const currentYearStatsValues = [];



      const { data } = await this.$http.get(url);

      currentYearStatsValues.push(data.produce, data.refinery, data.export);

      this.currentYearStats = this.stats[1].map((element, index) => {
        element.value =
          currentYearStatsValues[index].toLocaleString() + this.unit;
        return element;
      });
    }
  },
  async created() {
    await this.initialize();
  }
};
</script>
